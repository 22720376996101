@import "src/styles/mixins.module";
@import "src/styles/variables.module";

.tooltip {
  font-size: 12px;
  white-space: pre-line;

  box-shadow: unset !important;

  background-color: #ffffff !important;
  color: $realblue !important;
  border: 1.5px solid $realblue !important;

  article:not(:first-child) {
    margin-top: 1em;
  }
}

.button {
  @include flex;
}

/* with tooltip */
.flex {
  @include flex(flex-start);
  display: inline-flex;
}

.icon {
  color: fade-out($slate, 0.25);
  margin-left: 3px;
  margin-top: -0.04em;
}
