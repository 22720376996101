@import "src/styles/mixins.module";
@import "src/styles/variables.module";

.footer {
  color: $slate;

  @include desktop {
    height: $footer-height;
  }

  @include mobile {
    height: $footer-height-mobile;
  }

  position: relative;
  width: 100%;
}
