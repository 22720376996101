@import "src/styles/mixins.module";
@import "src/styles/variables.module";

.connected {
  border-width: 1px;

  > * {
    @include flex;
  }
}

.address {
  font-size: 12px;
  font-weight: 600;
  margin: 0px 10px 0px 8px;
}
