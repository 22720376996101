@import "src/styles/mixins.module";
@import "src/styles/variables.module";

.button {
  @include flex;
  @include transition(background-color);

  background-color: transparent;
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  color: white;
  font-size: 14px;
  font-weight: 600;
  height: 38px;
  padding: 8px 15px 9px 16px;

  .msg {
    margin-left: 7px;
    margin-right: 2px;
  }

  &:hover {
    background-color: fade-out(#ffffff, 0);
    color: fade-out($blue, 0);
    text-decoration: none;
  }

  @include mobile {
    width: auto;
    min-width: 56px;
    height: 56px;
    border: none;
    border-radius: 56px;
    background-color: $realblue;
    color: #ffffff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.52);

    .msg {
      display: none;
    }
  }
}
