@import "src/styles/mixins.module";
@import "src/styles/variables.module";

$symbol: 16px;
$name: 14px;

.asset {
  @include flex(space-between);
  // Because only one line of data can exist, fix the height.
  height: (($symbol + $name) * $line-height);
  width: 100%;
  position: relative;
}

.header {
  text-align: left;
  width: 100%;
  margin-left: 1rem;
}

.symbol {
  display: flex;
  align-items: center;
}

.divide {
  color: $deepgray;
  margin-left: 15px;
  margin-right: 15px;
  font-size: $symbol;
  font-weight: 500;
  float: left;
  line-height: 20px;
}

.symbol_name {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.token_address {
  display: flex;
  margin-left: 2.5rem;
}

.name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $deepgray;
  margin-left: 10px;
  font-size: $symbol;
  font-weight: 500;
}

.address {
  display: inline-block;
  color: $brown;
  font-size: 12px;
  word-break: break-all;

  @include mobile {
    font-size: 9px;
  }
}

.footer {
  text-align: left;
}

.balance {
  color: $blue;
  font-size: 12px;
}

.verified_box {
  width: 54px;
  height: 18px;
  margin-left: 10px;
  padding: 0 7px;
  border-radius: 9px;
  background-color: #0222ba;
  text-align: center;
  display: flex;
  align-items: center;
}

.verified {
  width: 40px;
  height: auto;
  font-family: "Open Sans", arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
