@import "src/styles/mixins.module";
@import "src/styles/variables.module";

.button {
  @include flex;
  color: $deepgray;
  padding: 0px 4px;
  padding-left: 10px;
}

.button:hover {
  background-color: $semiblue;
  border-radius: 50px;
  padding-left: 10px;
  height: 28px;
}

.logo {
  margin-right: 5px;
  margin-top: 8px;
}

.name {
  margin-left: 4px;
  color: $deepgray;
  font-weight: 600;
  font-size: 15px;
}
