@import "src/styles/mixins.module";

.wrapper {
  width: 90vw;
  max-width: 432px;
  margin: 0 auto;
}

.component {
  padding: 0;
}

.title {
  color: $blue;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 18px;
}

.button {
  @include flex();
  background-color: white;
  border: 1px solid $blue;
  border-radius: 10px;
  color: $blue;
  font-size: 18px;
  font-weight: 700;
  padding: 24px 24px;
  width: 100%;

  &:hover {
    background-color: #dee4ff;
  }

  &--installed {
    background-color: #dee4ff;
    border-color: transparent !important;
  }

  & + & {
    margin-top: 20px;
  }
}
