*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  line-height: $line-height;
  font-family: $font-family-swap;
}

body {
  background: $realblue;
  color: $text;
  margin: 0;
  overflow-x: hidden;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;

  border: 0;
  border-top: 1px solid $hr-shadow;
  border-bottom: 1px solid $hr;

  margin-top: 20px;
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 600;
  margin: 0;
}

p,
ul,
ol,
dl,
dd {
  margin: 0;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

strong {
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}

[target="_blank"] {
  @include link($blue, $blue);
  &:hover {
    text-decoration: underline;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  text-align: left;
}

button,
input,
textarea {
  border: 0;
  padding: 0;
  outline: 0;
  background: transparent;
  color: inherit;
  font: inherit;
}

button {
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: unset;
  }
}

input:disabled,
textarea:disabled,
input:read-only {
  opacity: 0.5;
  cursor: default;
}

textarea {
  resize: none;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
